import { Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { GridComponent, GridDataResult } from "@progress/kendo-angular-grid";
import { StorageService } from '../../../app/utils/StorageHelper';
import { GLAccount } from '../../services/glaccounts/glaccounts.model';
import { GLAccountsService } from '../../services/glaccounts/glaccounts.service';
import { PriceAdjustment, emptyPriceAdjustment } from '../../services/price-adjustments/price-adjustments.model';

@Component({
  selector: 'app-price-preview-adjustment',
  templateUrl: './price-preview-adjustment.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PricePreviewAdjustmentComponent implements OnInit {
  @ViewChildren(GridComponent) grids: QueryList<GridComponent> = new QueryList<GridComponent>();
  @Input() priceAdjustmentSet: any;
  public gridView: GridDataResult;
  constructor(
    private glAccountsService: GLAccountsService,
  ) {
  }
  priceAdjustments: PriceAdjustment[] = [];

  loading: boolean = true;
  query = { PageSize: 50, SortTerm: '', PageNumber: 1, SearchTerm: '' };

  ngOnInit() {
    this.loading = true;
    this.loadListData();
    this.loading = false;
  }

  onRefresh() {
    this.ngOnInit();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;

    this.priceAdjustmentSet = `<root>` + this.priceAdjustmentSet + `</root>`

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(this.priceAdjustmentSet, "text/xml");

    this.priceAdjustments = [];

    const priceAdjustmentNodes = xmlDoc.getElementsByTagName('PriceAdjustment');

    await Promise.all(Array.from(priceAdjustmentNodes).map(async (singleAdjustment, index) => {
      await this.populateAdjustmentsGrid(singleAdjustment);
    }));

    this.gridView = {
      data: this.priceAdjustments,
      total: this.priceAdjustments.length,
    };

    this.loading = false;
  }


  async populateAdjustmentsGrid(singleAdjustment) {
    const currentAdjustment: PriceAdjustment = { ...emptyPriceAdjustment };
    currentAdjustment.PriceAdjustmentName = singleAdjustment.querySelector('PriceAdjustmentName')?.innerHTML ?? '';
    currentAdjustment.PriceAdjustmentAmount = parseFloat(singleAdjustment.querySelector('PriceAdjustmentAmount')?.innerHTML ?? '');
    const debitGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(singleAdjustment.querySelector('DebitGLAccount')?.innerHTML ?? '')));
    currentAdjustment.DebitGLAccountName = debitGLAccount?.name;
    currentAdjustment.DebitGLAccountNumber = debitGLAccount?.number;
    const creditGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(singleAdjustment.querySelector('CreditGLAccount')?.innerHTML ?? '')));
    currentAdjustment.CreditGLAccountName = creditGLAccount?.name;
    currentAdjustment.CreditGLAccountNumber = creditGLAccount?.number;
    this.priceAdjustments.push(currentAdjustment);
  }

  async getGLAccountByERPId(erpid) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = erpid;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingGLAccounts: GLAccount[] = await (await this.glAccountsService.getList(params)).body;
    if (existingGLAccounts.length == 1) {
      return existingGLAccounts[0];
    }
  }

}
