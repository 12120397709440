export interface PriceAdjustment {
  PriceAdjustmentName: string;
  PriceAdjustmentAmount: number;
  DebitGLAccountName: string;
  DebitGLAccountNumber: string;
  CreditGLAccountName: string;
  CreditGLAccountNumber: string;
  UserCanEdit: string;
  AdjustmentAffectsMargins: string;
  AdjustmentModifiesUnitPrice: string;
}

export const emptyPriceAdjustment: PriceAdjustment = {
  PriceAdjustmentName: '',
  PriceAdjustmentAmount: null,
  DebitGLAccountName: '',
  DebitGLAccountNumber: '',
  CreditGLAccountName: '',
  CreditGLAccountNumber: '',
  UserCanEdit: '',
  AdjustmentAffectsMargins: '',
  AdjustmentModifiesUnitPrice: ''
}