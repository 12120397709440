import { Component, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../../utils/StorageHelper';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
import { CompaniesUsersService } from '../../services/companies-users/companies-users.service';
import { CompanyUser } from '../../services/companies-users/companies-users.model';
import { CompaniesService } from '../../services/companies/companies.service';
import {
  Company,
  emptyCompany,
} from '../../services/companies/companies.model';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import {
  Authentication,
  emptyAuthentication,
} from '../../services/authentication/authentication.model';
import { UsersService } from '../../services/users/users.service';
import { User, emptyUser } from '../../services/users/users.model';
import { Button } from '@progress/kendo-angular-buttons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSingleComponent } from '../../price/users/user-single/user-single.component';
import { SettingsService } from '../../services/settings/settings.service';
import { Setting, emptySetting } from '../../services/settings/settings.model';
import { LookupsService } from '../../services/lookups/lookups.service';
import { Lookup, emptyLookup } from 'src/app/services/lookups/lookups.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('profileMenuButton') profileButton: Button;

  isAuthenticated: boolean;
  title = 'Rockton Pricing Management';
  companies: Company[] = [];
  selectedCompanyID: string;
  @Output() authenticationChange = new EventEmitter();
  public isAdmin: boolean;
  public isRocktonAdmin: boolean;
  public userID: string;
  //emptyAuth: Authentication = emptyAuthentication;
  user: User = emptyUser;
  public userTooltip: string;
  settings: Setting[] = [];
  public erpNames: Lookup[] = [];

  constructor(
    private router: Router,
    private companiesUsersService: CompaniesUsersService,
    private companiesService: CompaniesService,
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private modalService: NgbModal,
    private settingsService: SettingsService,
    private lookupService: LookupsService
  ) { }

  ngOnInit(): void {
    //Check to see if we need to refresh the cache before we do anything.
    this.evaluateCache()

    this.userID = StorageService.UserID();
    if (this.userID) {
      this.loadData();
      this.RetrieveCompanies();
      this.isAdmin = StorageService.IsAdmin();
      this.isRocktonAdmin = StorageService.IsRocktonAdmin();
    } else {
      this.authenticationService.authenticationChangedEvent.subscribe(
        (event) => {
          this.userID = StorageService.UserID();
          if (this.userID) {
            this.loadData();
            this.RetrieveCompanies();
            this.isAdmin = StorageService.IsAdmin();
            this.isRocktonAdmin = StorageService.IsRocktonAdmin();
          }
        },
      );
    }
  }

  async evaluateCache() {

    // Get the date we would like the cache to be expired from the Environment setting.
    const expireCacheBefore = new Date(environment.expireCacheBefore);

    // Get the date that was the last time the User refreshed their cache.
    const lastCacheRefresh = StorageService.LastCacheRefresh();

    if (lastCacheRefresh) {
      const lastRefresh = new Date(lastCacheRefresh);

      // If the last refresh of the User's cache was before the Expire Cache Before setting
      // then we need to clear out all of their cache and reset the last refreshed date.
      if (lastRefresh < expireCacheBefore) {
        StorageService.clearAll();
        StorageService.setLastCacheRefresh(expireCacheBefore.toString());
      }
    }
    else {
      // We have never captured the last time the cache was refreshed so clear it out and capture the date for today.
      StorageService.clearAll();
      const newCacheRefresh = new Date();
      StorageService.setLastCacheRefresh(newCacheRefresh.toString());
    }
  }

  async getSettings(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const params = { PageSize };
      const resp = await this.settingsService.getList(params);
      this.settings = resp.body;
    } catch (e) {
      this.settings = [];
    }
    return this.settings;
  }

  async loadData() {
    if (this.userID) {
      this.user = await Promise.resolve(this.getUserById(this.userID));
      this.userTooltip = this.user.firstName + " " + this.user.lastName;
      //console.log(this.userTooltip);
    }
  }

  async getERPNames() {
    const Filter_OptionSet = 'ERPName';
    const params = { Filter_OptionSet };
    this.erpNames = await this.lookupService.getList(params);
    return this.erpNames;
  }

  async getUserById(id) {
    return await this.usersService.getById(id);
  }

  private async RetrieveCompanies() {
    //const userID = StorageService.UserID();
    if (this.userID) {
      // Go Retrieve the CompaniesUsers to determine which Companies the Logged In User has access to.
      const Filter_UserID = this.userID;
      const params = { Filter_UserID };
      const existingCompaniesUsers: CompanyUser[] = await (
        await this.companiesUsersService.getList(params)
      ).body;

      // Go Retrieve the Companies that are associated with this User.
      if (existingCompaniesUsers.length > 0) {
        // Clear the existing companies.
        this.companies = [];

        // Add the new Company records for the user.
        for (const value of existingCompaniesUsers) {
          const company = await this.companiesService.getById(value.companyID);
          this.companies.push(company);
        }
      }

      const lastSelectedCompany = StorageService.CompanyID();

      // If the user picked a company previously and the lastSelectedCompany 
      // is in array of Companies that the user has access to.
      if (lastSelectedCompany && this.companies.find(e => e.id == lastSelectedCompany)) {
        // Default it to last selected Company.
        this.selectedCompanyID = lastSelectedCompany;
        environment.companyId = lastSelectedCompany;
      }
      else {
        // Just default it to the first Company.
        this.selectedCompanyID = this.companies[0].id;
        environment.companyId = this.companies[0].id;
        this.onSelectBoxChanged(this.selectedCompanyID, this.companies)
      }

    }
  }

  editUserProfile(user) {
    const modalRef = this.modalService.open(UserSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'dialog';
    modalRef.componentInstance.idOfUser = this.user.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      modalRef.close();
    });
  }

  toggleMenu(event: Event) {
    event.preventDefault();
    $('#sideNav').toggleClass('collapse');
  }

  winLocation(term) {
    return this.router.url.includes(term);
  }

  onHelp(event: Event) {
    let helpRoot = "https://rpm.rocktonsoftware.com/help/index.htm";
    switch (true) {
      case (this.winLocation("attribute-maps")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=640";
          break;
        }
      case (this.winLocation("catalogs")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=660";
          break;
        }
      case (this.winLocation("catalog-sheets")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=670";
          break;
        }
      case (this.winLocation("currenc")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=230";
          break;
        }
      case (this.winLocation("customer-attribute-value")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=590";
          break;
        }
      case (this.winLocation("customer-attribute")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=205";
          break;
        }
      case (this.winLocation("customer")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=200";
          break;
        }
      case (this.winLocation("document-attribute")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=240";
          break;
        }
      case (this.winLocation("gl-account")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=250";
          break;
        }
      case (this.winLocation("historical-sales")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=680";
          break;
        }
      case (this.winLocation("item-attribute-value")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=600";
          break;
        }
      case (this.winLocation("item-attribute")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=212";
          break;
        }
      case (this.winLocation("item-currenc")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=214";
          break;
        }
      case (this.winLocation("item")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=210";
          break;
        }
      case (this.winLocation("location")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=260";
          break;
        }
      case (this.winLocation("price-book")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=30";
          break;
        }
      case (this.winLocation("price-calculation")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=20";
          break;
        }
      case (this.winLocation("price-schedules")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=90";
          break;
        }
      case (this.winLocation("price-sheet")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=40";
          break;
        }
      case (this.winLocation("pricing-conditions")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=620";
          break;
        }
      case (this.winLocation("pricing-diagnostic")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=320";
          break;
        }
      case (this.winLocation("pricing-filter-value")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=190";
          break;
        }
      case (this.winLocation("pricing-filter")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=80";
          break;
        }
      case (this.winLocation("pricing-scenario-document-value")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=310";
          break;
        }
      case (this.winLocation("pricing-scenario")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=310";
          break;
        }
      case (this.winLocation("pricing-tree")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1200";
          break;
        }
      case (this.winLocation("quantity-break")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=60";
          break;
        }
      case (this.winLocation("related-records")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=690";
          break;
        }
      case (this.winLocation("reports")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1150";
          break;
        }
      case (this.winLocation("rounding-rule")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=70";
          break;
        }
      case (this.winLocation("setting")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=330";
          break;
        }
      case (this.winLocation("sync-connectors")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1280";
          break;
        }
      case (this.winLocation("sync-maps")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=980";
          break;
        }
      case (this.winLocation("sync-queues")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=930";
          break;
        }
      case (this.winLocation("sync-readers")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=990";
          break;
        }
      case (this.winLocation("sync-sets")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1270";
          break;
        }
      case (this.winLocation("sync-writers")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1000";
          break;
        }
      case (this.winLocation("system-job-logs")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1130";
          break;
        }
      case (this.winLocation("system-jobs")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=1190";
          break;
        }
      case (this.winLocation("uofm-schedule")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=220";
          break;
        }
      case (this.winLocation("uofm")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=225";
          break;
        }
      case (this.winLocation("user")):
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=330";
          break;
        }
      default:
        {
          let newTab = window.open();
          newTab.location.href = helpRoot + "?context=3";
          break;
        }
    }
  }

  logout(event: Event) {
    console.log('Logout', event);
    this.companies = [];
    let emptyAuth = emptyAuthentication;
    emptyAuth.activeUser = null;
    emptyAuth.activeCompany = null;
    emptyAuth.activeERP = 'None';
    this.isAdmin = false;
    this.isRocktonAdmin = false;
    this.selectedCompanyID = '';
    this.userID = '';
    this.userTooltip = '';
    this.authenticationService.AuthenticationChanged(emptyAuth);
    this.router.navigate(['/auth/']);
  }

  async onSelectBoxChanged(id: string, arr) {
    environment.companyId = id;
    const companyName = arr.find((col) => col.id === id).name;
    let emptyAuth = emptyAuthentication;
    emptyAuth.activeCompany = emptyCompany;
    emptyAuth.activeCompany.name = companyName;
    emptyAuth.activeCompany.id = id;

    //this.erpNames = await Promise.resolve(this.getERPNames());
    //await this.getSettings(null);
    //let erpNameSetting: Setting = this.settings.find(erpSetting => erpSetting.name == 'ERPName')
    //let erpNameSelected: string = this.erpNames.find(erp => erp.id == +erpNameSetting.value).name;    
    //emptyAuth.activeERP = erpNameSelected;

    this.authenticationService.AuthenticationChanged(emptyAuth);
    this.router.navigate(['/']);
  }
}
