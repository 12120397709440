<!--*ngIf="gridView && gridView.total > 0"-->
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [reorderable]="true"
            [pageable]="false"
            [filterable]="false"
            [resizable]="true"
            [sortable]="false"
            kendoGridSelectBy="id">
  <ng-template kendoGridNoRecordsTemplate>
    <div>No Price Adjustments</div>
  </ng-template>

  <kendo-grid-column field="PriceAdjustmentName" title="Adjustment Name" width = "200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  </kendo-grid-column>
  <kendo-grid-column field="PriceAdjustmentAmount" title="Amount" width = "100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  </kendo-grid-column>
  <kendo-grid-column field="DebitGLAccountName" title="Debit GL Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  </kendo-grid-column>
  <kendo-grid-column field="DebitGLAccountNumber" title="Debit GL Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  </kendo-grid-column>
  <kendo-grid-column field="CreditGLAccountName" title="Credit GL Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  </kendo-grid-column>
  <kendo-grid-column field="CreditGLAccountNumber" title="Credit GL Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  </kendo-grid-column>
</kendo-grid>
