export interface PricePreview {
  documentLineID: number;
  priceScheduleID: string;
  priceScheduleName: string;
  modifiedUnitPrice: number;
  rank: number;
  priceWasFound: boolean;
  statusCode: number;
  errorMessage: string;
  pricingDiagnosticRunID: number;
  priceAdjustments: string;
  dynamicChoice: boolean;
}

export const emptyPricePreview: PricePreview = {
  documentLineID: null,
  priceScheduleID: '',
  priceScheduleName: '',
  modifiedUnitPrice: null,
  rank: null,
  priceWasFound: false,
  statusCode: null,
  errorMessage: '',
  pricingDiagnosticRunID: null,
  priceAdjustments: '',
  dynamicChoice: false
}
