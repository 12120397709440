<kendo-dialog title="Price Preview"
              [minWidth]="250"
              (close)="onClose()"
              [width]="'65%'">

  <div class="card p-3" style="background-color: rgb(234, 234, 234); max-height: 75vh; overflow: auto;">

    <div class="form-check mb-3" style="display: flex; align-items: center;">
      <input class="form-check-input" type="checkbox" id="showPriceNotFound" [(ngModel)]="showPriceNotFound" (change)="filterData()" style="margin: 0; flex-shrink: 0;">
      <label class="form-check-label" for="showPriceNotFound" style="margin: 0 0 0 1.25rem; line-height: 1; padding-top: 2px;">
        Show Prices Not Found
      </label>
    </div>

    <kendo-grid [data]="filteredGridData"
                [pageSize]="query.PageSize"
                [skip]="(query.PageNumber - 1) * query.PageSize"
                [pageable]="true"
                [sortable]="true"
                [resizable]="true"
                [sort]="sort"
                (sortChange)="sortChange($event)"
                [rowClass]="rowCallback">

      <kendo-grid-column field="rank" title="Rank" width="50" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      </kendo-grid-column>

      <kendo-grid-column field="priceScheduleName" title="Price Schedule" width="250" [style]="{ 'white-space': 'nowrap' }" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      </kendo-grid-column>

      <kendo-grid-column field="modifiedUnitPrice" title="Price" width="100" [style]="{'text-align': 'right'}" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      </kendo-grid-column>

      <kendo-grid-column field="priceWasFound" title="Price Found" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.priceWasFound ? 'Yes' : 'No' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="priceAdjustments" title="Price Adjustments" width="100" [style]="{ 'white-space': 'nowrap' }" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.priceAdjustments ? 'Yes' : 'No' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="dynamicChoice" title="Dynamic Choice" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.dynamicChoice ? 'Yes' : 'No' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="pricingDiagnosticRunID" title="Diagnostic" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a href="javascript:void(0);" (click)="showPricingDiagnostic(dataItem.pricingDiagnosticRunID)">
            {{ dataItem.pricingDiagnosticRunID }}
          </a>
        </ng-template>
      </kendo-grid-column>

      <div *kendoGridDetailTemplate="let dataItem">
        <app-price-preview-adjustment [priceAdjustmentSet]="dataItem.priceAdjustments"></app-price-preview-adjustment>
      </div>
    </kendo-grid>
  </div>
</kendo-dialog>
