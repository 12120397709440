import { Component } from '@angular/core';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { StorageService } from '../../../app/utils/StorageHelper';
import { PricePreview, emptyPricePreview } from 'src/app/services/price-previews/price-previews.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingDiagnosticSingleComponent } from '../pricing-diagnostics/pricing-diagnostic-single/pricing-diagnostic-single.component';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-price-preview',
  templateUrl: './price-preview.component.html'
})
export class PricePreviewComponent {
  previewSet: any;
  pricePreviews: PricePreview[];
  query = { PageSize: 50, SortTerm: 'priceScheduleName', PageNumber: 1, SearchTerm: '' };
  showPriceNotFound: boolean = false;
  loading: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {
  }

  public mySelection: string[] = [];
  public gridData: any[];
  public sort: SortDescriptor[] = [{ field: 'priceScheduleName', dir: 'asc' }];
  filteredGridData: any[];

  async ngOnInit() {
    this.loading = true;
    await this.loadListData();
    this.sortList();
    this.loading = false;
  }

  onClose() {
    this.activeModal.close();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.sortList();
  }

  private sortList(): void {
    this.filteredGridData = orderBy(this.filteredGridData, this.sort);
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.dynamicChoice === 1) {
      return {
        style: 'background-color: #ffcccc;'
      };
    }
    return {};
  }

  public async loadListData(): Promise<void> {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;

    this.pricePreviews = [];
    let previewXML = (new DOMParser()).parseFromString(this.previewSet, "application/xml")
    const pricePreviewNodes = previewXML.getElementsByTagName("DocumentLine");

    if (pricePreviewNodes && pricePreviewNodes.length > 0) {
      for (let i = 0; i < pricePreviewNodes.length; i++) {
        const singleRecord = pricePreviewNodes[i];
        await this.populatePreviewGrid(singleRecord);
      }
    }
    this.gridData = this.pricePreviews;
    this.filterData();
    this.loading = false;
  }

  filterData() {
    if (this.showPriceNotFound) {
      this.filteredGridData = this.gridData;
    } else {
      this.filteredGridData = this.gridData.filter(item => item.priceWasFound === true);
    }
    this.filteredGridData = [...this.filteredGridData];
    this.sortList();
  }

  async populatePreviewGrid(singleRecord: Element) {
    let priceAdjustments = singleRecord.getElementsByTagName('PriceAdjustments')[0]?.innerHTML ?? '';
    let documentLineID = singleRecord.getElementsByTagName('DocumentLineID')[0]?.innerHTML ?? '';
    let priceScheduleID = singleRecord.getElementsByTagName('PriceScheduleID')[0]?.innerHTML ?? '';
    let priceScheduleName = singleRecord.getElementsByTagName('PriceScheduleName')[0]?.innerHTML ?? '';
    let rank = singleRecord.getElementsByTagName('Rank')[0]?.innerHTML ?? '';
    let modifiedUnitPrice = singleRecord.getElementsByTagName('ModifiedUnitPrice')[0]?.innerHTML ?? '';
    let priceWasFound = singleRecord.getElementsByTagName('PriceWasFound')[0]?.innerHTML ?? '';
    let pricingDiagnostRunID = singleRecord.getElementsByTagName('PricingDiagnosticRunID')[0]?.innerHTML ?? '';
    let statusCode = singleRecord.getElementsByTagName('StatusCode')[0]?.innerHTML ?? '';
    let errorMessage = singleRecord.getElementsByTagName('ErrorMessage')[0]?.innerHTML ?? '';
    let dynamicChoice = singleRecord.getElementsByTagName('DynamicChoice')[0]?.innerHTML ?? '';

    var currentPreview: PricePreview = { ...emptyPricePreview };
    currentPreview.priceAdjustments = priceAdjustments;
    currentPreview.documentLineID = Number(documentLineID);
    currentPreview.priceScheduleID = priceScheduleID;
    currentPreview.priceScheduleName = priceScheduleName;
    currentPreview.rank = (rank && parseFloat(rank) > 0) ? parseFloat(rank) : null;
    currentPreview.modifiedUnitPrice = Number(modifiedUnitPrice);
    currentPreview.priceWasFound = priceWasFound === "1";
    currentPreview.pricingDiagnosticRunID = Number(pricingDiagnostRunID);
    currentPreview.statusCode = Number(statusCode);
    currentPreview.errorMessage = errorMessage;
    currentPreview.dynamicChoice = dynamicChoice === "1";
    this.pricePreviews.push(currentPreview);
  }

  public showPricingDiagnostic(ID) {
    const modalRef = this.modalService.open(PricingDiagnosticSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.idOfPricingDiagnostic = ID;
  }

}


